import React from 'react'
import { Link } from 'gatsby'
import { Wrapper, Grid, H, P } from '@farewill/ui'

import PATHS from 'paths'
import ExternalLink from 'components/ExternalLink'
import DefaultLayout from 'layouts/DefaultLayout'

const Security = () => (
  <DefaultLayout
    title="Security policy"
    description="Farewill's security policy."
  >
    <Grid container>
      <Grid.Item spanFromM={9} spanFromL={6}>
        <H size="XL" decorative>
          Farewill security
        </H>

        <Wrapper separator>
          <H size="M">We protect your data</H>
          <P>
            All data is written to multiple disks instantly, backed up daily,
            and stored in multiple locations. We restrict access to sensitive
            data to only those employees that need it to perform their roles.
          </P>
        </Wrapper>

        <Wrapper separator>
          <H size="M">Your data is sent and stored encrypted</H>
          <P>
            Whenever your data is in transit between you and us, everything is
            encrypted and sent using HTTPS. All database data and will PDF
            documents are stored in an encrypted format.
          </P>
        </Wrapper>

        <Wrapper separator>
          <H size="M">We protect your billing information</H>
          <P>
            All credit card transactions are processed using secure encryption —
            the same level of encryption used by leading banks, via our
            PCI-Compliant payment provider {}
            <ExternalLink href="https://stripe.com">Stripe</ExternalLink>.
          </P>
        </Wrapper>

        <Wrapper separator>
          <H size="M">Have a concern? Need to report an incident?</H>
          <P>
            Have you noticed abuse, misuse, an exploit, or experienced an
            incident with your account? Please visit our{' '}
            <Link to={PATHS.GENERAL.SECURITY_REPORTING}>
              vulnerability reporting page
            </Link>{' '}
            for details on how to securely submit a report.
          </P>
        </Wrapper>
      </Grid.Item>
    </Grid>
  </DefaultLayout>
)

export default Security
